
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { ICreditHistoryService, StripeService } from "@/service";
import { useStore } from "vuex";
import { SubscriptionModel } from "@/model";
import PurchaseSubscription from "./components/PurchaseSubscription.vue";

@Options({
  components: {
    PurchaseSubscription,
  },
})
export default class Subscriptions extends Vue {
  @Inject("CreditHistoryService") service: ICreditHistoryService;

  public togglePurchaseModel: boolean = false;
  public response: Array<SubscriptionModel> = [];

  public store = useStore();

  public loading: boolean = false;

  mounted() {
    if (this.$route.params.subscriptions == "0")
      this.togglePurchaseModel = true;
    else this.bindData();
  }

  public bindData() {
    this.loading = true;

    if (this.stripeId) {
      let service = new StripeService();

      service.getSubscriptions(this.stripeId).then((response) => {
        this.loading = false;

        if (response.filter((s) => s.isBasePlan).length > 0)
          this.response = response;
        else this.togglePurchaseModel = true;
      });
    }
  }

  public onCancelPurchase() {
    this.togglePurchaseModel = false;
  }

  get stripeId() {
    return this.store.getters.getStripeId;
  }
}
