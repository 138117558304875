
import { Vue, Options, setup } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";

import { IAuthenticationService } from "@/service";
import { UpdatePasswordRequest,CreateUserRequestModel } from "@/model";
import { Settings } from "@/config";

@Options({
  validations: {
    request: {
      password: {
        required,
        minLength: function (value: any) {
          let validation = false;
          if (value && value != "" && value.length >= 8) validation = true;

          return validation;
        },
        uppercase: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /^(?=.*?[A-Z])/.test(value);

          return validation;
        },
        lowercase: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /^(?=.*?[a-z])/.test(value);
          return validation;
        },
        number: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /[0-9]/.test(value);
          return validation;
        },
        special: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /[#?!@$%^&*-]/.test(value);
          return validation;
        },
      },
      confirmPassword: { required },
    },
  },
})
export default class ResetPassword extends Vue {
  @Inject("authService") service: IAuthenticationService;

  public request = new UpdatePasswordRequest();

  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public loading: boolean = false;

  public v$ = setup(() => this.validate());

  public store = useStore();

  mounted() {
    let user: CreateUserRequestModel = this.store.getters.getCurrentUser;
    if(typeof(user) !== 'undefined')
    {
      this.store.dispatch("logout");
    }
  }



  validate() {
    return useVuelidate();
  }

  public updatePassword() {

    this.v$.$touch();

    if (
      !this.v$.$invalid &&
      this.request.password == this.request.confirmPassword
    ) {
      this.loading = true;
      this.service
        .resetPassword(this.$route.query.uuid, this.request)
        .then((response) => {
          this.loading = false;
          this.$router.push("/");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status == 500)
            this.store.dispatch("showSnack", {
              message: error.response.data,
              snackBarClass: "snack--bar__error",
            });
        });
    }
  }

  public getErrors(request: boolean) {
    return request
      ? "fa-times-circle fa--danger"
      : "fa-check-circle fa--success";
  }

  get logo() {
    return Settings.AppLogo;
  }
}
