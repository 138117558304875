import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "body__wrapper",
    style: _normalizeStyle(_ctx.themeStyles)
  }, [
    _createVNode(_component_Snackbar),
    _createVNode(_component_router_view)
  ], 4))
}