
import { Vue, Options, setup } from "vue-class-component";
import { Inject } from "vue-property-decorator";

import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  sameAs,
} from "@vuelidate/validators";

import { IUserService } from "@/service";
import { CreateUserRequestModel, UsersResponseModel } from "@/model";
import { Settings } from "@/config";

@Options({
  validations: {
    request: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: {
        required,
        minLength: function (value: any) {
          let validation = false;
          if (value && value != "" && value.length >= 8) validation = true;

          return validation;
        },
        uppercase: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /^(?=.*?[A-Z])/.test(value);

          return validation;
        },
        lowercase: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /^(?=.*?[a-z])/.test(value);
          return validation;
        },
        number: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /[0-9]/.test(value);
          return validation;
        },
        special: function (value: any) {
          let validation = false;
          if (value && value != "") validation = /[#?!@$%^&*-]/.test(value);
          return validation;
        },
      },
      confirmPassword: {
        required,
      },
      phone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
    termsAndConditions: {
      checked: function (value: any) {
        return value;
      },
    },
  },
})
export default class Registration extends Vue {
  @Inject("userService") service: IUserService;

  public store = useStore();
  public request = new CreateUserRequestModel();
  public v$ = setup(() => this.validate());

  public termsAndConditions: boolean = false;

  mounted() {
    let uuid: string | any = this.$route.params.userId;

    this.service
      .getUserById(uuid)
      .then((user) => {
        if (user && user.email) {
          this.request.id = user.id;
          this.request.email = user.email;
        } else this.$router.push("/");
      })
      .catch((error) => {
        console.log(error);

        this.$router.push("/");
      });
  }

  validate() {
    return useVuelidate();
  }

  public newUser() {
    this.v$.$touch();

    if (
      !this.v$.$invalid &&
      this.request.password == this.request.confirmPassword &&
      this.termsAndConditions
    ) {
      this.request.type = "ADDUSER";
      this.request.enabled = true;
      this.service
        .registerUser(this.request)
        .then((response) => {
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showSnack", {
              message: error.response.data,
              snackBarClass: "snack--bar__error",
            });
        });
    }
  }

  public getErrors(request: boolean) {
    return request
      ? "fa-times-circle fa--danger"
      : "fa-check-circle fa--success";
  }

  get logo() {
    return Settings.AppLogo;
  }
}
