
import { Settings } from "@/config";
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export default class Help extends Vue {
    @Prop() currentPage: string;

    get leadName() {
        return `${Settings.LeadName.charAt(0).toUpperCase()}${Settings.LeadName.slice(1)}s`;
    }
}
