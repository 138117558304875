
import {Vue} from "vue-class-component";
import {Inject, Prop} from "vue-property-decorator";
import {useStore} from "vuex";

import {DataResponse, AppParamsModel, AppParamsRequestModel} from "@/model";
import {Settings} from "@/config";
import {IAppService} from "@/service/app.service";

export default class AppParams extends Vue {
  @Prop() appResponse: DataResponse<AppParamsModel> ;
  @Inject("appService") appService: IAppService;

  public request = new AppParamsRequestModel();
 // public appResponse: DataResponse<AppParamsModel> = new DataResponse();


  public email: string;

  created() {

  }

  mounted() {
  //  this.getAllAppParams();
  }

  public getAllAppParams() {
    this.request._loading = true;
    this.appService.getAllAppParams().then((response: any) => {
      this.request._loading = false;
      this.appResponse = response;
      console.log("response="+response.data[0]['paramName']);
    });
  }

  public updateParams() {

    this.appService.setAllAppParams(this.appResponse).then((response: any) => {
     // this.request._loading = false;
      //this.appResponse = response;
      console.log(response);
    });

    console.log(this.appResponse.data);

  }


  public close() {
    this.$emit("close");
  }


  get appName() {
    return Settings.AppName;
  }
}
