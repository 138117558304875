import { IBaseService, BaseService } from './base.service';
import {AppParamsRequestModel,AppParamsModel, DataResponse} from '@/model';

export interface IAppService extends IBaseService<AppParamsRequestModel,AppParamsModel> {
    getAllAppParams(): Promise<DataResponse<AppParamsModel>>;
    setAllAppParams(request: DataResponse<AppParamsModel>): Promise<any>;
}

export class AppService extends BaseService<AppParamsRequestModel,AppParamsModel> implements IAppService {

    constructor() {
        super('admin');
    }

    public getAllAppParams(): Promise<DataResponse<AppParamsModel>>{
        return this.httpGet('admin/getAllAppParams', null).then(response => {
            return response.data;
        })
    }

    public setAllAppParams(request: DataResponse<AppParamsModel>): Promise<any> {
        return this.httpPost('admin/setAllAppParams', request).then(response => {
            return response;
        })
    }
}

