
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import moment from "moment";

import { useStore } from "vuex";

import { IDashboardService, ILeadsService, ISitesService } from "@/service";
import {
  DashboardRequestModel,
  DashboardModel,
  DataResponse,
  LeadsRequestModel,
  LeadsModel,
  SitesRequestModel,
} from "@/model";

import BarChart from "@/components/chart/BarChart.vue";
import { Settings } from "@/config";

@Options({
  components: {
    BarChart,
  },
})
export default class Dashboard extends Vue {
  @Inject("dashboardService") service: IDashboardService;
  @Inject("leadsService") leadsService: ILeadsService;
  @Inject("sitesService") siteService: ISitesService;

  public store = useStore();
  public subscription: any = null;

  public response: DashboardModel = new DashboardModel();
  public xAxis: Array<string> = [];
  public chartData: Array<string> = [];
  public activeSites: number = 0;

  public leadsResponse: DataResponse<LeadsModel> = new DataResponse();

  public loading: boolean = false;

  created() {
    this.getDashboardInfo();

    let request = new SitesRequestModel();
    this.siteService.getSites(request).then((response) => {
      this.activeSites = response.data.filter(s => s.enabledFlag && !s.deletedFlag && s.status == "APPROVED").length;
    });
  }

  mounted() {
    this.subscription = this.store.subscribe((mutations, type) => {
      if (mutations.type == "onChangeSiteId") this.getDashboardInfo();
    });
  }

  unmounted() {
    if (this.subscription) this.subscription();
  }

  public navigateToNextPage(page: string, query?: string | any) {
    if(page == "leads") this.$router.push({name: "Leads", params: {page: query}});
    else this.$router.push("/" + page);
  }

  public getDashboardInfo() {
    if (this.store.getters.getSiteId) {
      this.loading = true;
      const request = new DashboardRequestModel();
      request.siteId = this.store.getters.getSiteId;
      this.service.getDashboardInfo(request).then((response) => {
        this.response = response;
        this.bindChart();
        this.getLeads();
        this.loading = false;
      });
    } 
  }

  public getLeads() {
    this.loading = true;
    const request = new LeadsRequestModel();
    request.sortColumn = "creationDateTime";
    request.ascending = false;
    request.siteId = this.store.getters.getSiteId;
    this.leadsService.getAvailableLeads(request).then((response) => {
      this.loading = false;
      this.leadsResponse = response;
    });
  }

  public bindChart() {
    this.xAxis = [];
    this.chartData = [];

    this.xAxis = Object.keys(this.response.leadsByDate);
    this.chartData = Object.values(this.response.leadsByDate);
  }

  applyFilters(date: any) {
    if (!date) return (date = "");

    date = moment(date).format("MM-DD-YYYY");
    return date;
  }

  get leadName() {
    return Settings.LeadName;
  }
}

