
import { Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { AdditionalDetailsModel } from "@/model";

export default class AdditionalDetails extends Vue {
    @Prop() details: Array<AdditionalDetailsModel>;

    public response: Array<AdditionalDetailsModel> = [];

    created() {
        this.response = this.details;
    }

    public close() {
        this.$emit("close", false);
    }

}
