import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "form-field form-field--is-active" }
const _hoisted_2 = { class: "form-field__control" }
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "autocomplete"
}
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        type: "input",
        class: "form-field__select",
        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.showAutoComplete = true;}),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedItem) = $event)),
        readonly: ""
      }, null, 512), [
        [_vModelText, _ctx.selectedItem]
      ]),
      _createElementVNode("label", {
        for: _ctx.label,
        class: "form-field__select--label"
      }, _toDisplayString(_ctx.label) + " *", 9, _hoisted_3),
      _createElementVNode("i", {
        class: _normalizeClass(["fa form-field__select--arrow", {
                    'fa-sort-down': !_ctx.showAutoComplete,
                    'fa-sort-up': _ctx.showAutoComplete
                }])
      }, null, 2)
    ]),
    (_ctx.showAutoComplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["autocomplete__item", {
                    'autocomplete__item--active': item == _ctx.selectedItem
                }]),
              key: index,
              onClick: ($event: any) => (_ctx.update(item))
            }, _toDisplayString(item), 11, _hoisted_5))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, _ctx.clickOutSideAutoComplete]
  ])
}