
import axios from "axios";
import { Vue, Options, setup } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import {
  CreateUserRequestModel,
  SitesModel,
  SitesRequestModel,
  CreateMultiSiteUserRequestModel
} from "@/model";
import { ISitesService, IUserService } from "@/service";

import { useStore } from "vuex";
import Selectbox from "@/components/controls/Selectbox.vue";

@Options({
  components: {
    Selectbox,
  },
  validations: {
    request: {
      email: { required, email },
    },
  },
})
export default class InviteUser extends Vue {
  @Inject("userService") service: IUserService;
  @Inject("sitesService") sitesService: ISitesService;

  public request = new CreateMultiSiteUserRequestModel();
  public sites: Array<SitesModel> = [];
  public roleType: Array<string> = ["ADMIN", "CLIENT"];

  public v$ = setup(() => this.validate());
  public store = useStore();

  created() {
    this.request.role = "CLIENT";
    this.request.type = "INVITEUSER";
    let user: CreateMultiSiteUserRequestModel = this.store.getters.getCurrentUser;
    this.request.invitedBy = user.id;

    const request = new SitesRequestModel();
    this.sitesService.getSites(request).then((response) => {
      this.sites = response.data;

      this.request.siteIds = [this.store.getters.getSiteId];
    });
  }

  validate() {
    return useVuelidate();
  }

  public close() {
    this.$emit("close");
  }

  public onUpdateRole(request: CreateUserRequestModel, selectedRole: string) {
    request.role = selectedRole;
  }

  public assign() {
    this.v$.$touch();
    axios.defaults.headers.common["Authorization"] = "";
    if (!this.v$.$invalid) {
      if (!this.request.credits) this.request.credits = 0;
      
      this.service
        .addMultiSiteUser(this.request)
        .then((response) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.store.getters.accessToken;

          this.store.dispatch("showAlert", {
              message: "User Invite Request was successful. You will Receive an Email Once approved",
              snackBarClass: "fa-check",
              type: "success",
            });

          this.$emit("assigned");
        })
        .catch((error) => {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + this.store.getters.accessToken;

          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: "User email already registered",
              snackBarClass: "fa-times",
              type: "error",
            });
        });
    }
  }
}
