
import { Vue, Options, setup } from "vue-class-component";
import { Prop, Inject } from "vue-property-decorator";

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import { AssignUserToMultiSiteRequestModel, SitesModel, SitesRequestModel } from "@/model";
import { ISitesService, IUserService } from "@/service";

import { useStore } from "vuex";

@Options({
  validations: {
    request: {
      email: { required, email },
    },
  },
})
export default class AssignUser extends Vue {
  @Inject("userService") service: IUserService;
  @Inject("sitesService") sitesService: ISitesService;

  public request = new AssignUserToMultiSiteRequestModel();
  public sites: Array<SitesModel> = [];
  public isUnAssign = false;

  public v$ = setup(() => this.validate());
  public store = useStore();

  validate() {
    return useVuelidate();
  }

  created() {
    const request = new SitesRequestModel();
    this.sitesService.getSites(request).then((response) => {
      this.sites = response.data;

      this.request.siteIds = [this.store.getters.getSiteId];
    });
  }

  public OnChangeAssignChange(e:any)
  {
    this.$nextTick(() => {
      if(this.isUnAssign)
      {
        this.request.siteIds = [];
      }
      else
      {
        this.request.siteIds = [this.store.getters.getSiteId];
      }
    });
  }
  public close() {
    this.$emit("close");
  }

  public assign() {
    this.v$.$touch();
    if (!this.v$.$invalid) {
      this.request.unAssign = this.isUnAssign;
      this.service
        .assignUserToMultiSite(this.request)
        .then((response) => {
          if(this.isUnAssign)
          {
            this.$emit("unassigned");
          }
          else
          {
            this.$emit("assigned");
          }


        })
        .catch((error) => {
          if (error.response.status == 400)
            this.store.dispatch("showAlert", {
              message: "Please enter already registered user email",
              snackBarClass: "fa-times",
              type: "error",
            });
        });
    }
  }
}
