
import { ISitesService } from "@/service";
import { Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";
import { useStore } from "vuex";

export default class UploadFile extends Vue {
  @Prop() adminSiteId: number;

  @Inject("sitesService") service: ISitesService;
  templateUrl: string = require("../../../assets/ManualUpload_Template.csv");

  public store = useStore();

  public close() {
    this.$emit("close");
  }

  public upload() {
    let control: any = this.$refs.upload;

    if (control.files.length) {
      this.service
        .uploadSiteTraffic(this.adminSiteId, control.files[0])
        .then((response) => {
          this.store.dispatch("showAlert", {
            message: "Your file uploaded successfully and getting proceesed.",
            snackBarClass: "fa-check",
            type: "success",
          });

          this.close();
        });
    }
  }
}
