import { IBaseService, BaseService } from './base.service';
import { StripeSubscriptionRequestModel, StripeSubscriptionUpdateRequestModel,CreateStripeSubscriptionModel,
    UpdateStripeSubscriptionModel, SitesSubscriptionModel,DeleteStripeSubscriptionModel,SubscriptionSessionUpdateModel,DataResponse } from '@/model';

export interface IStripeSubscriptionService extends IBaseService<StripeSubscriptionRequestModel, CreateStripeSubscriptionModel> {
    createStripeSubscription(request: StripeSubscriptionRequestModel): Promise<CreateStripeSubscriptionModel>;
    createStripeSubscriptionSession(request: StripeSubscriptionRequestModel): Promise<CreateStripeSubscriptionModel>;
    deleteStripeSubscription(siteId: number): Promise<DeleteStripeSubscriptionModel>;
    getStripeSubscription(siteId: number): Promise<SitesSubscriptionModel>;
    updateStripeSubscription(request: StripeSubscriptionUpdateRequestModel): Promise<UpdateStripeSubscriptionModel>;
}

export class StripeSubscriptionService extends BaseService<StripeSubscriptionRequestModel, CreateStripeSubscriptionModel> implements IStripeSubscriptionService {

    constructor() {
        super('admin');
    }

    public createStripeSubscription(request: StripeSubscriptionRequestModel): Promise<CreateStripeSubscriptionModel> {
        return this.httpPost('admin/createStripeSubscription', request).then(response => {
            return response.data;
        })
    }

    public createStripeSubscriptionSession(request: StripeSubscriptionRequestModel): Promise<CreateStripeSubscriptionModel> {
        return this.httpPost('admin/createStripeSubscriptionSession', request).then(response => {
            return response.data;
        })
    }


    public updateStripeSubscription(request: StripeSubscriptionUpdateRequestModel): Promise<UpdateStripeSubscriptionModel> {
        return this.httpPost('admin/updateStripeSubscription', request).then(response => {
            return response.data;
        })
    }

    public deleteStripeSubscription(siteId: number): Promise<DeleteStripeSubscriptionModel> {
        return this.httpGet('admin/deleteStripeSubscription', { siteId: siteId }).then(response => {
            return response.data;
        })
    }
    public getStripeSubscription(siteId: number): Promise<SitesSubscriptionModel> {
        return this.httpGet('admin/getStripeSubscription/' + siteId, {}).then(response => {
            return response.data;
        })
    }
}


