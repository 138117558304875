
import {Vue,Options} from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import {Inject, Prop} from "vue-property-decorator";
import {useStore} from "vuex";

import {AutoLeadsModel,CreateUserRequestModel} from "@/model";
import {Settings} from "@/config";
import AutoDataDelivery from "../components/AutoDataDelivery.vue";
import { ILeadsService} from "@/service";

@Options({
  components: {
    AutoDataDelivery,
  },
})

export default class ActiveAutoDataSummary extends BaseComponent {
  @Prop() activeAutoData: AutoLeadsModel ;
  @Prop() projectName: string ;
  @Prop() siteId: number ;
  @Inject("leadsService") service: ILeadsService;


  public store = useStore();
  public isAdmin = false;
  public isSuperAdmin: boolean = false;
  public toggleAutoDataDeliveryModel: boolean = false;


  created() {
    const user: CreateUserRequestModel = this.store.getters.getCurrentUser;
    this.isAdmin = user.role.indexOf("ADMIN") > -1;
    this.isSuperAdmin = user.role.indexOf("SUPERADMIN") > -1;
  }

  mounted() {
  }

  private deleteAutoLead()
  {
    this.confirm(
        `Are you sure you want to  delete Auto Data profile for site `+this.projectName
    ).then((confirm: boolean) => {
      if (confirm) {
         this.service.deleteAutoLeads(this.siteId)
         .then((response: any) => {
              if(response.includes("SUCCESS:"))
              {
                this.store.dispatch("showAlert", {
                  message: response,
                  snackBarClass: "fa-check",
                  type: "success",
                });
                this.store.dispatch("updateSiteId", this.siteId);
                this.close();
              }
              else
              {
                this.store.dispatch("showAlert", {
                  message: response,
                  snackBarClass: "fa-times",
                  type: "error",
                });
              }
            })
      }
    });

  }

  private autoLeadConfirm() {
    this.$emit("closeAndEditAutoDataLead");
}

  public getFormattedDate(dt: string)
  {
    let myDate = new Date(dt);
    return ('00'+(myDate.getMonth()+1)).slice(-2)+"/" + ('00'+((myDate.getDate()))).slice(-2) + "/" + myDate.getFullYear();
  }


  public getFormattedEmail(emailAddressesDelimited: string)
  {
    let emailFormatted: string ="";
    emailAddressesDelimited.split(",").forEach((e) => (emailFormatted += e.trim() + "<br><br>"));
    emailFormatted=emailFormatted.substr(0,emailFormatted.length -"<br><br>".length)
    return emailFormatted;
  }

  public close() {
    this.$emit("close");
  }

  public dummy()
  {

  }

  get appName() {
    return Settings.AppName;
  }

  closeAutoDataDeliveryModel() {
    this.toggleAutoDataDeliveryModel = false;
  }

}
