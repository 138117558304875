
import { Vue, Options } from "vue-class-component";
import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";
import { AuthenticationResponse, Dictionary } from "@/model";
import "./styles/main.scss";

import Snackbar from "@/components/controls/Snackbar.vue";

import { DIContainer } from "./dicontainer";

import { useStore } from "vuex";
import { StripeService } from "./service";
import { Settings } from "./config";

@Options({
  components: { Snackbar },
})
export default class App extends DIContainer {
  public store = useStore();

  themeStyles: Dictionary<string> = {};

  created() {
    const token = this.store.getters.accessToken;
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    this.createAxiosResponseInterceptor();   

    this.themeStyles[
      `--login-background`
    ] = `url('${process.env.VUE_APP_LOGIN_BG}')`;
    this.themeStyles[
      `--account-background`
    ] = `url('${process.env.VUE_APP_ACCOUNT_BG}')`;
    this.themeStyles[`--font-general`] = `${process.env.VUE_APP_FONT_GENRAL}`;
    this.themeStyles[`--color-1`] = `#${process.env.VUE_APP_COLOR1}`;
    this.themeStyles[`--color-2`] = `#${process.env.VUE_APP_COLOR2}`;
    this.themeStyles[`--color-3`] = `#${process.env.VUE_APP_COLOR3}`;
    this.themeStyles[`--color-4`] = `#${process.env.VUE_APP_COLOR4}`;
    this.themeStyles[`--color-5`] = `#${process.env.VUE_APP_COLOR5}`;
    this.themeStyles[`--color-6`] = `#${process.env.VUE_APP_COLOR6}`;
    this.themeStyles[`--color-7`] = `#${process.env.VUE_APP_COLOR7}`;
    this.themeStyles[`--color-8`] = `#${process.env.VUE_APP_COLOR8}`;
    this.themeStyles[`--color-9`] = `#${process.env.VUE_APP_COLOR9}`;
    this.themeStyles[`--color-10`] = `#${process.env.VUE_APP_COLOR10}`;
    this.themeStyles[`--color-11`] = `#${process.env.VUE_APP_COLOR11}`;    
  }

  mounted() {
      const favicons: any = document.querySelectorAll("link[rel*='icon']");
      favicons.forEach((element: any) => {
        element.href = element.href.replace("/img/icons", "/img/icons/" + Settings.AppFavIcon);
      });
  }

  createAxiosResponseInterceptor() {
    const interceptor = axios.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error) => {
        const status = error.response ? error.response.status : null;

        if(this.$router.currentRoute && this.$router.currentRoute.value && this.$router.currentRoute.value.meta && this.$router.currentRoute.value.meta.anonymous)
          return Promise.resolve(true);

        if (status !== 401) {
          return Promise.reject(error);
        }

        axios.interceptors.response.eject(interceptor);

        return this.store
          .dispatch("refreshToken")
          .then((response: AuthenticationResponse) => {
            if (response.success) {
              error.response.config.headers["Authorization"] =
                "Bearer " + this.store.getters.accessToken;
              return axios(error.response.config);
            } else {
              this.store.dispatch("logout");

              this.$router.push("/login");

              return Promise.reject(error);
            }
          })
          .catch((error) => {
            this.store.dispatch("logout");

            this.$router.push("/login");

            return Promise.reject(error);
          })
          .finally(this.createAxiosResponseInterceptor);
      }
    );
  }
}
