
import { Vue } from "vue-class-component";

export default class DownloadFile extends Vue {
  public close() {
    this.$emit("close");
  }

  public download(format: string) {
    this.$emit("download", "leads." + format);
  }
}
