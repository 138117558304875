
import { Options, setup } from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import { Prop, Inject } from "vue-property-decorator";

import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";

import { AssignCreditRequestModel, UsersResponseModel } from "@/model";
import { ICreditHistoryService } from "@/service";

import { useStore } from "vuex";

@Options({
  validations: {
    request: {
      creditToTransfer: {
        required,
        minValue: minValue(1),
      },
    },
  },
})
export default class AssignCredit extends BaseComponent {
  @Prop() user: UsersResponseModel;

  @Inject("CreditHistoryService") service: ICreditHistoryService;

  public request = new AssignCreditRequestModel();
  public invalidCredit: boolean = false;

  public v$ = setup(() => this.validate());
  public store = useStore();

  validate() {
    return useVuelidate();
  }

  public close() {
    this.$emit("close");
  }

  public assign() {
    this.v$.$touch();
    this.request.toUserId = this.user.id;
    if (!this.v$.$invalid) {
      let credits = this.store.getters.getCredits
        ? this.store.getters.getCredits
        : 0;

      if (credits >= this.request.creditToTransfer || this.store.getters.isSuperAdmin) {
        this.confirm(
          `Are you sure you want to assign ${this.request.creditToTransfer} credits to '${this.user.email}'?`
        ).then((confirm: boolean) => {
          if (confirm) {
            this.service
              .delegateCredit(this.request)
              .then((response) => {
                this.$emit("assigned");
              })
              .catch((error) => {
                if (error.response.status == 400)
                  this.store.dispatch("showAlert", {
                    message: "There is not enough credit to transfer",
                    snackBarClass: "fa-times",
                    type: "error",
                  });
              });
          }
        });
      } else {
        this.invalidCredit = true;
      }
    }
  }
}
