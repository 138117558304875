
import { Vue } from "vue-class-component";
import { Inject, Prop } from "vue-property-decorator";
import { useStore } from "vuex";

import { ISitesService } from "@/service";
import { SendMailRequest } from "@/model";
import { Settings } from "@/config";

export default class PixelTracking extends Vue {
  //@Prop() pixelUrl: any;
  @Prop() pixelInstallCode: any;
  @Prop() pixelSiteName: string;
  @Inject("sitesService") service: ISitesService;

  termsAndConditions: boolean = false;
  pixelCode: string = "";
  public userAlert: boolean = false;
  public userMessage:string;

  public store = useStore();
  public request = new SendMailRequest();

  mounted() {
   // this.pixelCode = this.service.getPixelCode(this.pixelUrl);
    this.pixelCode = this.pixelInstallCode;
        this.pixelCode = this.pixelCode.trim();
  }

  public copyClipboard() {
    this.store.dispatch("showSnack", {
      message: "Successfully Copied to Clipboard",
      snackBarClass: "snack--bar__success",
    });
    this.userAlert = true;
    this.userMessage = "Successfully Copied to Clipboard";
  }

  public clearPixel() {
    this.$emit("closePixel", false);
  }

  public sendMail() {
    this.request.siteName = this.pixelSiteName;
    this.request.message = this.pixelCode;
    this.request.message = this.request.message
      .replace(/>/g, "&gt;")
      .replace(/</g, "&lt;");
    this.service.sendMail(this.request).then(
      (response) => {
        this.clearPixel();
        this.store.dispatch("showSnack", {
          message: `Email Successfully sent to ${this.request.email}`,
          snackBarClass: "snack--bar__success",
        });
        this.store.dispatch("showAlert", {
          message:
              `Email Successfully sent to ${this.request.email}`,
          snackBarClass: "fa-check",
          type: "success",
        });
      },
      (error) => {
        this.clearPixel();
        this.store.dispatch("showSnack", {
         message: `Email Successfully sent to ${this.request.email}`,
          snackBarClass: "snack--bar__success",
        });
        this.store.dispatch("showAlert", {
          message:
              `Email Successfully sent to ${this.request.email}`,
          snackBarClass: "fa-check",
          type: "success",
        });
      }
    );
  }

  get appName() {
    return Settings.AppName;
  }
}
