
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import { ILeadsService, ISitesService } from "@/service";
import {
  SiteUploadModel,
  ServiceTitanFileModel
} from "@/model";

import UploadFile from "./components/UploadFile.vue";
import UploadFilePurchaseLeads from "./components/UploadFilePurchaseLeads.vue";
import UploadServiceTitan from "./components/UploadServiceTitan.vue";
import Pagination from "@/components/controls/Pagination.vue";
import { Settings } from "@/config";
import axios from "axios";

@Options({
  components: {
    Pagination,
    UploadFile,
    UploadFilePurchaseLeads,
    UploadServiceTitan
  },
})
export default class ManualUpload extends Vue {
  @Inject("leadsService") service: ILeadsService;
  @Inject("sitesService") siteService: ISitesService;

  toggleUploadFile: boolean = false;
  toggleServiceTitan: boolean = false;
  loading: boolean = false;

  public store = useStore();
  public subscription: any = null;

  public uploadData: Array<SiteUploadModel> = [];

  public selectedLeadsArr: Array<number> = [
    1,
    2,
      ];

  adminSiteId: number;

  public serviceTitanResponse: Array<ServiceTitanFileModel> = [];

  created() {
    this.siteService.getAllAdminSites().then((response: any) => {
      this.adminSiteId = response.data.length ? response.data[0].id : 6;
      this.getData();
      this.getServiceTitanData();
    });
  }

  private getData() {
    this.loading = true;
    this.siteService.getSiteUpload(this.adminSiteId).then((response) => {
      this.loading = false;
      this.uploadData = response.sort((n1, n2) => {
          if (n1.createdTime > n2.createdTime) {
            return -1;
          }

          if (n1.createdTime < n2.createdTime) {
            return 1;
          }

          return 0;
        });
    });
  }

  private getServiceTitanData() {
    this.loading = true;
    this.siteService.getServiceTitanFiles().then((response) => {
      this.loading = false;
      this.serviceTitanResponse = response.sort((n1, n2) => {
        if (n1.timeSent > n2.timeSent) {
          return -1;
        }

        if (n1.timeSent < n2.timeSent) {
          return 1;
        }

        return 0;
      });
    });
  }

  updatePurchaseModel() {
    this.toggleUploadFile = false;
  //  this.store.dispatch("updateCredits");

    //this.selectedLeads = [];

    //this.$emit("purchaseCompleted");
  }


  closeUploadFile() {
    this.toggleUploadFile = false;

    this.getData();
  }

  closeServiceTitan() {
    this.toggleServiceTitan = false;

  }

  public downloadFile(item: SiteUploadModel) {
    this.loading = true;
    this.service.exportFile(`${item.fileName.replace(".csv", "")}_Result.xlsx`, item.orderId).then(response => {
      this.loading = false;
    });
  }

  public downloadServiceTitanFile(item: ServiceTitanFileModel) {
    this.loading = true;
    this.siteService.exportServiceTitanFile( item.fileName,item.id).then(response => {
      this.loading = false;
    });
  }

  get siteId() {
    return this.store.getters.getSiteId;
  }

  get leadName() {
    return Settings.LeadName;
  }
}
