import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_stripe_element_card = _resolveComponent("stripe-element-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.payNow)
      ? (_openBlock(), _createBlock(_component_stripe_element_card, {
          key: 0,
          ref: "elementRef",
          pk: _ctx.publishableKey,
          onToken: _ctx.tokenCreated
        }, null, 8, ["pk", "onToken"]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
    }, "Generate token")
  ]))
}