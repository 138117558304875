import { IBaseService, BaseService } from './base.service';
import {
    DataResponse,
    SitesModel,
    SitesRequestModel,
    CreateSiteRequestModel,
    CreateSiteModel,
    PixelSiteRequestModel,
    PixelSiteModel,
    SendMailRequest,
    SiteUploadModel,
    PixelInstallSiteRequestModel,
    PixelInstallSiteModel,
    SetStatCounterRequestModel,
    SetStatCounterModel,
    ServiceTitanFileModel
} from '@/model';

export interface ISitesService extends IBaseService<SitesRequestModel, SitesModel> {
    getSites(request: SitesRequestModel): Promise<DataResponse<SitesModel>>;
    getSitesWithLeads(request: SitesRequestModel): Promise<DataResponse<SitesModel>>;
    getSitesWithAvailableLeadsCount(request: SitesRequestModel): Promise<DataResponse<SitesModel>>;
    getAllAdminSites(): any;
    createSite(request: CreateSiteRequestModel): Promise<CreateSiteModel>;
    deleteSite(siteId: number): Promise<any>;
    generatePixel(request: PixelSiteRequestModel): Promise<PixelSiteModel>;
    getPixelInstallData(request: PixelInstallSiteRequestModel): Promise<PixelInstallSiteModel>;
    sendMail(request: SendMailRequest): Promise<any>;
    changeStatus(siteId: number, status: string): Promise<any>;
    uploadSiteTraffic2(siteId: number, file: File, purchasedFields: Array<any>): any;
    uploadServiceTitan(file: File, dryRun: string): any;
    uploadSiteTraffic(siteId: number, file: File): Promise<any>;
    enableSite(siteId: number): Promise<any>;
    disableSite(siteId: number): Promise<any>;
    setStatCounter(request: SetStatCounterRequestModel): Promise<SetStatCounterModel>;
    getSiteUpload(siteId:number): Promise<Array<SiteUploadModel>>;
    getServiceTitanFiles(): Promise<Array<ServiceTitanFileModel>>;
    exportServiceTitanFile(fileName: string, fileId: number): Promise<any>;
    getCredits(siteId: number): Promise<any>;
    getPixelCode(url: string): string;
}
export class SitesService extends BaseService<SitesRequestModel, SitesModel> implements ISitesService {

    constructor() {
        super('admin')
    }

    public getSites(request: SitesRequestModel): Promise<DataResponse<SitesModel>> {
        return this.searchPost(request, 'getAllSites').then(response => {
            return response;
        })
    }

    public getSitesWithLeads(request: SitesRequestModel): Promise<DataResponse<SitesModel>> {
        return this.searchPost(request, 'getAllSitesWithLeads').then(response => {
            return response;
        })
    }

    public getSitesWithAvailableLeadsCount(request: SitesRequestModel): Promise<DataResponse<SitesModel>> {
        return this.searchPost(request, 'getAllSitesWithAvailableLeadsCount').then(response => {
            return response;
        })
    }

    public getSiteUpload(siteId: number): Promise<Array<SiteUploadModel>> {
        return this.httpGet(`admin/getSiteUpload/${siteId}`, null).then(response => {
            return response.data;
        })
    }

    public getServiceTitanFiles(): Promise<Array<ServiceTitanFileModel>> {
        return this.httpGet(`admin/thirdparty/getServiceTitanFiles`, null).then(response => {
            return response.data;
        })
    }

    public getAllAdminSites(): any {
        return this.httpGet('admin/getAllAdminSites', null).then(response => {
            return response;
        })
    }

    public createSite(request: CreateSiteRequestModel): Promise<CreateSiteModel> {
        return this.httpPost('admin/site', request).then(response => {
            return response.data;
        })
    }

    public changeStatus(siteId: number, status: string): Promise<any> {
        return this.httpGet('admin/site/changeStatus/' + siteId, { status: status }).then(response => {
            return response.data;
        })
    }

    public enableSite(siteId: number): Promise<any> {
        return this.httpPut('admin/site/enable/' + siteId, null).then(response => {
            return response.data;
        })
    }

    public disableSite(siteId: number): Promise<any> {
        return this.httpPut('admin/site/disable/' + siteId, null).then(response => {
            return response.data;
        })
    }

    public deleteSite(siteId: number): Promise<any> {
        return this.httpPut('admin/site/delete/' + siteId, null).then(response => {
            return response.data;
        })
    }

    public generatePixel(request: PixelSiteRequestModel): Promise<PixelSiteModel> {
        return this.httpGet('admin/generatePixel', request).then(response => {
            return response.data;
        })
    }

    public setStatCounter(request: SetStatCounterRequestModel): Promise<SetStatCounterModel> {
        return this.httpGet('admin/setAdvancedStats', request).then(response => {
            return response.data;
        })
    }

    public getPixelInstallData(request: PixelInstallSiteRequestModel): Promise<PixelInstallSiteModel> {
        return this.httpPost('admin/getPixelInstallData', request).then(response => {
            return response.data;
        })
    }

    public sendMail(request: SendMailRequest): Promise<any> {
        return this.httpPost('admin/sendMail', request).then(response => {
            return response.data;
        })
    }

    public uploadSiteTraffic2(siteId: number, file: File, purchasedFields: Array<any>) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("purchasedFields", JSON.stringify(purchasedFields));

        return this.upload(formData, `site/uploadSiteTraffic/${siteId}`).then(response => {
            return response;
        })
    }

    public uploadServiceTitan(file: File, dryRun: string) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("dryRun", dryRun);

        return this.upload(formData, `thirdparty/uploadServiceTitan`).then(response => {
            return response;
        })
    }

    public exportServiceTitanFile(fileName: string, fileId: number): Promise<any> {
        return this.httpGet('admin/thirdparty/exportServiceTitanFile/'+ fileId, "blob").then((response) => {
            let fileUrl = window.URL.createObjectURL(new Blob([response.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", fileName);
            fileLink.style.display = "none";
            document.body.appendChild(fileLink);

            fileLink.click();
            document.body.removeChild(fileLink);
        })
    }

    public uploadSiteTraffic(siteId: number, file: File) {
        const formData = new FormData();
        formData.append("file", file);

        return this.upload(formData, `site/uploadSiteTraffic/${siteId}`).then(response => {
            return response.data;
        })
    }

    public getPixelCode(url: string): string {
        let pixelText = 'var xmlhttp = new XMLHttpRequest();xmlhttp.open(\'GET\', \''+url+'?siteURL=\' + encodeURI(window.location.href), true);xmlhttp.send();';
        pixelText = btoa(pixelText+'xmlhttp.open(\'GET\', \'https://c.statcounter.com/12739036/0/fd12ed7f/1/\',true);xmlhttp.send();');
        //  st.text = "var xmlhttp = new XMLHttpRequest();xmlhttp.open('GET', '${url}?siteURL=' + encodeURI(window.location.href), true);xmlhttp.send();");
        //  st.text = st.text+"xmlhttp.open('GET', 'https://c.statcounter.com/12738275/0/d8687834/1/',true);xmlhttp.send();";
        return `
            <script type="text/javascript">
                //<![CDATA[
                (function () { 
                var st = document.createElement('script'); 
                st.type = 'text/javascript'; st.async = true;
                st.text = atob("${pixelText}");
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(st, s);})(); 
                //]]>
            </script>
            `;
    }

    public getCredits(siteId: number): Promise<any> {
        return this.httpGet('admin/site/getCredits/'+siteId, null).then(response => {
            return response.data;
        })
    }
}



