
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { useStore } from "vuex";

import { Settings } from "@/config";
import { ICreditHistoryService,ISitesService, IStripeSubscriptionService } from "@/service";
import { AllCreditValueModel, StripeCheckoutModel,  DataResponse,
  SitesModel,SitesSubscriptionModel,SitesRequestModel } from "@/model";

import Selectbox from "@/components/controls/Selectbox.vue";

import { StripeCheckout } from "@vue-stripe/vue-stripe";

@Options({
  components: {
    Selectbox,
    StripeCheckout,
  },
})
export default class PurchaseCredits extends Vue {
  @Inject("CreditHistoryService") service: ICreditHistoryService;
  @Inject("sitesService") sitesService: ISitesService;
  @Inject("stripeSubscriptionService") stripeSubscriptionService: IStripeSubscriptionService;

  public store = useStore();
  public response: Array<AllCreditValueModel> = [];
  public credits: number = 0;
  public creditsToggle: boolean = false;
  public publishableKey: string = "";
  public lineItems: any = [];
  public payNow: boolean = false;
  public successUrl: string = "";
  public cancelUrl: string = "";
  public sessionId: string;
  public sites: DataResponse<SitesModel> = new DataResponse();
  public siteId: number;
  public siteName: string;
  public fieldsError: boolean = false;
  public siteSubscriptionAlreadyPresent: boolean = false;
  public fieldsMessage:string;
  public selectedSiteSubscription: SitesSubscriptionModel = new SitesSubscriptionModel();
  public sitesRequest = new SitesRequestModel();
  public selectedSite: SitesModel = new SitesModel();

  public loading: boolean = false;
  public validationError: boolean = false;

  public amount: number = 0;

  created() {
    this.publishableKey = Settings.PublicKey;
    this.successUrl = window.location.origin + "/success";
    this.cancelUrl = window.location.origin + "/cancel";
    this.getAllCreditValues();
    this.getAllSites();
    this.updateOnProjectSelection();
  }

  public getAllSites() {

    this.sitesService.getSites(this.sitesRequest).then((response) => {
      this.sites = response;
      console.log(response);

      this.siteId = this.store.getters.getSiteId;
      this.siteName = this.store.getters.getSiteName;
      this.getStripeSubscriptionForSite(this.siteId );
    });
  }

  public getSelectedSite() {
    this.sites.data.forEach((s: SitesModel) => {
      if (s.id== this.siteId)
      {
        this.selectedSite = s;
      }
    });
  }

  public updateOnProjectSelection()
  {
    if(this.siteId !=null)
    {
      this.getStripeSubscriptionForSite(this.siteId);
      this.getSelectedSite();
    }
  }

  public getStripeSubscriptionForSite(siteId: number) {
    this.siteSubscriptionAlreadyPresent = false;
    this.stripeSubscriptionService
        .getStripeSubscription(siteId)
        .then((response) => {
          this.selectedSiteSubscription = response;
          console.log(this.selectedSiteSubscription);
          if(typeof(this.selectedSiteSubscription.status) !== 'undefined')
          {
            this.siteSubscriptionAlreadyPresent = true;
            this.fieldsError = false;
            this.fieldsMessage = ""
          }else
          {
            this.fieldsError = true;
            this.fieldsMessage = "There is No Active Subscription for this Project. Please setup an Subscription by Clicking here.  <a href=\"/sites\" >(Subscription)</a>";

          }

        });
  }

  private getAllCreditValues() {
    this.loading = true;
    this.service.getAllCreditValues().then((response) => {
      this.loading = false;
      this.response = response;
      this.updatePrice(this.response[0].creditPoint);
    });
  }

  public updatePrice(credits: number) {
    this.credits = credits;
    this.creditsToggle = false;
    this.amount = this.credits * 1;
  }

  public clickOutSideCredits() {
    this.creditsToggle = false;
  }

  public purchase() {
    if (this.credits >= this.response[0].creditPoint) {
      this.lineItems = [
        {
          price: Settings.StripeCreditId,
          quantity: parseInt(this.credits.toString()),
        },
      ];

      let request = new StripeCheckoutModel();
      request.successURL = this.successUrl;
      request.cancelURL = this.cancelUrl;
      request.mode = "payment";
      request.lineItems = this.lineItems;

      this.service.createSession(request).then((sessionId) => {
        this.payNow = true;
        this.sessionId = sessionId;

        this.store.dispatch("addPurchaseCredits", {
          credits: this.credits,
          amount: this.amount,
        });
        this.store.dispatch("updateSiteSubscriptionId", this.selectedSiteSubscription.id);
        //this.store.dispatch("updateSiteId", this.selectedSite.id);
        //this.store.dispatch("updateSiteName", this.selectedSite.siteName);
        setTimeout(() => {
          (this.$refs.checkoutRef as any).redirectToCheckout();
        }, 1000);
      });
    } else {
      this.validationError = true;
    }
  }

  public cancel() {
    this.$emit("cancel");
  }

  public onCreditChange() {
    this.amount = this.credits > 0 ? this.credits : 0;

    this.validationError = this.credits < this.response[0].creditPoint;
  }

  get currentUser() {
    return this.store.getters.getCurrentUser;
  }
}
