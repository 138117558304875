
import { Vue, Options } from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { ICreditHistoryService, StripeService } from "@/service";
import { useStore } from "vuex";
import { InvoiceModel } from "@/model";


export default class Invoice extends Vue {

  public response: Array<InvoiceModel> = [];

  public store = useStore();

  public loading: boolean = false;

  mounted() {
    this.bindData();
    this.store.dispatch("updateCredits");
  }

  public bindData() {
    this.loading = true;

    let service = new StripeService();

    service.getInvoices(this.stripeId).then((response) => {
      this.loading = false;
      this.response = response;
    });
  }

  get stripeId() {
    return this.store.getters.getStripeId;
  }
}
