
import { Options, setup } from "vue-class-component";
import { BaseComponent } from "@/components/BaseComponent";
import { Prop, Inject } from "vue-property-decorator";

import useVuelidate from "@vuelidate/core";
//import vuejsDatepicker from "vuejs-datepicker"
import { required, minValue } from "@vuelidate/validators";

import { UpdateSubscriptionRequestModel, UsersResponseModel } from "@/model";
import { ICreditHistoryService } from "@/service";

import { useStore } from "vuex";

@Options({
  validations: {
    request: {
      subscriptionCredits: {
        required
      },
      subscriptionStartDay: {
        required
      },
    },
  },
})
export default class UpdateSubscription extends BaseComponent {
  @Prop() user: UsersResponseModel;

  @Inject("CreditHistoryService") service: ICreditHistoryService;

  public request = new UpdateSubscriptionRequestModel();
  public invalidCredit: boolean = false;
  public invalidStartDay: boolean = false;
  public isUpdateSubscription: boolean = false;

  public v$ = setup(() => this.validate());
  public store = useStore();

  validate() {
    return useVuelidate();
  }

  public close() {
    this.$emit("close");
  }

  public update() {
    this.v$.$touch();
    this.request.toUserId = this.user.id;
    this.request.subscriptionCredits = this.request.subscriptionCredits;
    this.request.subscriptionStartDay = this.request.subscriptionStartDay;
    if (!this.v$.$invalid) {
      /**
      let credits = this.store.getters.getCredits
        ? this.store.getters.getCredits
        : 0;
      **/
      if ( this.store.getters.isSuperAdmin) {
        this.confirm(
          `Are you sure you want to subscribe  ${this.request.subscriptionCredits} credits to '${this.user.email}'?`
        ).then((confirm: boolean) => {
          if (confirm) {
            this.service
              .updateSubscription(this.request)
              .then((response) => {
                this.$emit("updated");
              })
              .catch((error) => {
                if (error.response.status == 400)
                  this.store.dispatch("showAlert", {
                    message: "The subscription has failed",
                    snackBarClass: "fa-times",
                    type: "error",
                  });
              });
          }
        });
      } else {
        this.invalidCredit = true;
      }
    }
  }

  public getNumbers(start: number,stop: number,step : number = 1){
  return new Array(stop / step).fill(start).map((n,i)=>(i+1)*step);
}
}
